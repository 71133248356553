import { OrderTabsNames } from "./../models/OrderTabs.enum";
import { useEffectAfterMount } from "./../../common/hooks/useEffectAfterMount";
import { useDispatch } from "react-redux";
import { RootStateOrAny, useSelector } from "react-redux";

import {
  getOrderComments,
  getOrderDetails,
  getOrderHistory,
} from "./../../../connectors/orders/connectors/index";
import { addNewComment, setOrderComments, setOrderDetails, setOrderHistory } from "./../../../config/redux/orders/ordersPanel/orderPanelActions";

export const useValidatorData = () => {
  const dispatch = useDispatch();
  const {
    validatorPanelOpen,
  } = useSelector((state: RootStateOrAny) => state.validatorPanel);
  const { selectedValidators } = useSelector(
    (state: RootStateOrAny) => state.validators
  );

  return {
    validatorPanelOpen,
    selectedValidators,
  };
};
