import React from "react";
import { mergeStyles } from "office-ui-fabric-react";
import Price from "./edditOrder/Price";
import { Text, ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";
import PriceInput from "./PriceInput";
import { useTranslation } from "react-i18next";
import { IOrdersPriceShowLang } from "../../../../localization/abstract/client/orders";
const wrapper = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});
const mainWrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
})
const divider = mergeStyles({
  width: 250,
});
export interface PricesProps {
  value: number;
  setPrice?: any;
  disabled?: boolean
}
const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};
const Prices: React.FC<PricesProps> = ({ value, setPrice }) => {
  const { t } = useTranslation("orders");
  const priceShowNames: IOrdersPriceShowLang = t("priceShow", {
    returnObjects: true,
  });
  return (
    <div className={mainWrapper} >
      <Text styles={headerS}>{priceShowNames.estimatedOrderCost}</Text>
      <div className={wrapper}>
        <div className={divider}>
          {/* <Price name="netto" label="Cena netto" disabled={true} value={value.toString()}/> */}
          {
            <PriceInput
              value1={value ? value.toString() : ""}
              name="netPrice"
              label={priceShowNames.newValue}
              disabled={false}
              setPrice={setPrice}
            />
          }
        </div>
        <div className={divider}>
          <Price
            name="grossPrice"
            label={priceShowNames.grossValue}
            value={((value ? Number(value) : 0.0) * 1.08)}
            disabled={true}
          />
        </div>
      </div>
    </div>
  );
};
export default Prices;