import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { useSelector, RootStateOrAny } from "react-redux";
import { containerS } from "../../../styles/containerStyles";
import OrderBasicInfo from "./OrderBasicInfo";
import CommentInput from "../CommentInput";
import PaymentMethod from "./PaymentMethod";
import Prices from "../Prices";
import OrderParams from "../newOrder/OrderParams";
import NewFormBtns from "../../../../common/layout/form/NewFormBtns";
import { useOrderDetails } from "../../../hooks/useOrderDetailsForm";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import MessageContainer from "../../../../auth/components/register/forms/MessageContainer";
import FormDropDownReception from "../../../../common/layout/form/FormDropDownReception";

const OrderDetailsFrom: React.FC = () => {
  const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel);

  const [reception, setReception] = useState<any>({key: undefined});
  const [orderTypeItemId, setOrderTypeItemId] = useState<any>({name: ""});

  const {
    receptionPlaces,
    orderTypeItems,
    handleSubmit,
    cancelForm,
    initValues,
    isLoading,
    changePrice, 
    orderType
  } = useOrderDetails(reception);
  
    const [price, setPrice] = useState<number>(orderDetails.netPrice);

    useEffect(() => {
        if (orderDetails && orderDetails.netPrice) {
            setPrice(orderDetails.netPrice);
        }
    }, [orderDetails]);
    //
   useEffect(() => {
    if(orderDetails && orderDetails.netPrice){
    setPrice(orderDetails.netPrice);
    }
    }, [orderDetails]);
    //merge from master
  if(reception === 0 && initValues !== undefined && initValues.receptionPlace !== "") {
    setReception(orderDetails.receptionPlaceId);
    setOrderTypeItemId(orderDetails.orderTypeItemId);
    //setPrice(orderDetails.netPrice);
    //setBrutto(orderDetails.)
  }

  // const [receptionPlace] = useField("receptionPlace")
  // const [orderTypeItemId] = useField("orderTypeItemId")

  return (
    <div className={containerS}>
      <OrderBasicInfo activeOrder={orderDetails} />
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <FormDropDownReception
              name="receptionPlace"
              headerText="Punkt odbioru"
              options={receptionPlaces}
              onChange={changePrice}
              setReception={setReception}
              orderTypeItemId={orderTypeItemId}
              //setPrice={setPrice}
              //changePrice={changePrice}
            />
            <CommentInput
              value={initValues.additionalInfo ? initValues.additionalInfo : ""}
              name="additionalInfo"
              onChange={handleChange}
              label="Dodatkowe informacje"
            />
            <OrderParams
              headerText="Szczegóły"
              orderType={orderType}
              setFieldValue={setFieldValue}
              values={values}
              items={orderTypeItems}
              setPrice={setPrice}
              onChange={changePrice}
              setOrderTypeItemId={setOrderTypeItemId}
              reception={reception}
              price={price}
            />
            <PaymentMethod setFieldValue={setFieldValue} paymentType={orderDetails.paymentType} />
            <Prices 
              value={price !== null ? price : orderDetails.netPrice}
              setPrice={setPrice}
              disabled={price ? false : true}
            />
            <NewFormBtns positionAbsolute={true} submitBtnText="Zapisz" cancelForm={cancelForm} disabled={isLoading} disabledOnlySubmitButton={!price}/>
            {isLoading ? <ActivityIndicator/> : <MessageContainer/>}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrderDetailsFrom;