import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { reloadOrders, setSelectedListItem, toggleOrderPanel } from '../../../config/redux/client/clientActions';
import { RootStateOrAny } from 'react-redux';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../connectors/clients/connectors';
import { getOrderTypeItemsWithLang } from '../../../connectors/orderTypes/connectors';
import { FormikValues } from 'formik';
import { IResetForm } from '../../../features/common/models/resetForm.type';
import { addOrder, editOrder as apiEditOrder, getPickUpPoints } from '../../../connectors/orders/connectors';
import { setErrorAlert, setListMessage } from '../../../config/redux/alerts/alertsActions';
import { useTranslation } from 'react-i18next';
import i18n from '../../../localization/i18n';

export const useOrderForm = (companyId: string, departmentId: string, receptionPlace: any) => {
  const [isLoading, setIsLoading] = useState(false)
  const { selectedItem, formActive, editOrder, orderDetails, orderPanelOpen } = useSelector((state: RootStateOrAny) => state.client)
  const dispatch = useDispatch()
  const { t } = useTranslation("common");
  const [departments, setDepartments] = useState([]);
  const [receptionPlaces, setReceptionPlaces] = useState<any[]>([]);
  const [orderTypeItems, setOrderTypeItems] = useState([]);
  const [orderType, setOrderType] = useState({ name: "", id: "" });

  useEffect(() => {
    if (companyId) {
      setCompany();
    }
  }, [companyId]);

  useEffect(() => {
    if (orderPanelOpen === true) {
      getReceptionPlaces();
    } else {
      setReceptionPlaces([]);
    }
  }, [orderPanelOpen]);

  useEffect(() => {
    if (receptionPlace) {
      setOrderTypes(receptionPlace.key);
    }
  }, [receptionPlace, receptionPlaces])

  const initValues = useMemo(() => {
    return {
      date: orderDetails.date,
      type: orderDetails.type,
      receptionPlace: orderDetails.receptionPlaceId,
      status: orderDetails.status,
      number: orderDetails.number,
      additionalInfo: orderDetails.additionalInfo,
      orderTypeItemId: orderDetails.orderTypeItemId,
      departmentId: orderDetails.departmentId,
      quantity: orderDetails.quantity,
      vatRate: orderDetails.vatRate,
      netPrice: orderDetails.netPrice,
      grossPrice: orderDetails.grossPrice
    }
  }, [orderPanelOpen, orderDetails])

  const setOrderTypes = async (receptionPlaceKey: number) => {
    if (receptionPlaceKey !== undefined && receptionPlaces !== undefined) {
      const resid = receptionPlaces.find((item: any) =>
        item.key === receptionPlaceKey
      );
      if (resid !== undefined) {
        const res = await getOrderTypeItemsWithLang(resid.orderTypeId, i18n.language);
        //const data = await getOrderTypes();

        const arr = res.data.data.map((item: any) => {
          return { key: item.id, text: item.name, data: item.value, orderTypeId: item.orderTypeId };
        });

        const result = res.data.data.find(
          (item: any) => item.id === receptionPlaceKey
        );

        setOrderTypeItems(arr);
        // if (result !== undefined) {
        //   setOrderType(result);
        // }
      }
    }
  };

  const setCompany = async () => {

    const res = await getCompany(companyId);
    const company = res.data.data;
    setDepartments(
      company.departments.map((department: any) => {
        return {
          key: department.id,
          text: `${department.city} ${department.street}`,
        };
      })
    );
  };

  const getReceptionPlaces = async () => {
    const res = await getPickUpPoints(departmentId);
    const options = res.data.data.map((option: any) => {
      let msg = !option.isActive ? '(Nieaktywny)' : ''
      return {
        key: option.id,
        text: `${option.city} ${option.street} ${option.postalCode} ${msg}`,
        orderTypeId: option.orderTypeId,
        disabled: !option.isActive
      };
    });

    setReceptionPlaces(options);
  };

  const handleSubmit = async (values: FormikValues,
    { resetForm }: IResetForm) => {
    setIsLoading(true);
    const data = {
      receptionPlace: values.receptionPlace,
      additionalInfo: values.additionalInfo,
      quantity: values.quantity ? values.quantity.toString() : "1",
      orderTypeItemId: values.orderTypeItemId,
      netPrice: values.netPrice,
      grossPrice: values.grossPrice
    }
    try {
      editOrder !== true ? await addOrder(data) : await apiEditOrder(selectedItem.key, data);
      setIsLoading(false);
      dispatch(toggleOrderPanel({ isOpen: false, formActive: false }))
      dispatch(setSelectedListItem(''))
      var response = t("operationSuceeded");
      dispatch(setListMessage(response));
      dispatch(reloadOrders(true));
    }
    catch (err) {
      setIsLoading(false);
    }
  }

  const cancelForm = () => {
    dispatch(toggleOrderPanel({ isOpen: false, formActive }))
    dispatch(setSelectedListItem(''))
    var response = t("operationFailed");
    dispatch(setErrorAlert(response));
  }

  return {
    handleSubmit,
    cancelForm,
    isLoading,
    departments,
    initValues,
    orderTypeItems,
    orderType,
    orderDetails,
    receptionPlace,
    receptionPlaces
  }
}