import React from "react";
import { mergeStyles } from "office-ui-fabric-react"
import OrderDetailTxt from "./forms/edditOrder/OrderDetailTxt";
import { RootStateOrAny, useSelector } from "react-redux";
import { Reason } from "../../common/enums/reason.enum";
import { getReasonsById } from "../../../connectors/cancellationReasons/connectors";
import { useQuery } from "react-query";

const wrapper = mergeStyles({
  padding: 20
})

const OrderDetails: React.FC = () => {
  const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel)
  const { data } = useQuery(
    ["reasonForCancellation", Reason.OrderCancel],
    getReasonsById
  );
  let cancelReason = undefined;
  if (data) {
    for (let reason of data.data.data) {
      if (reason.id === orderDetails.reasonForCancellation) {
        cancelReason = reason.value
      }
    }
  }

  return (
    <div className={wrapper}>
      {orderDetails && (
        <div>
          <OrderDetailTxt
            title="Numer zamówienia"
            value={orderDetails.orderInfo.number}
          />
          <OrderDetailTxt
            title="Nazwa klienta"
            value={orderDetails.orderInfo.company}
          />
          <OrderDetailTxt
            title="Adres odbioru"
            value={orderDetails.orderInfo.receptionPlace}
          />
          <OrderDetailTxt
            title="Numer kontaktowy"
            value={orderDetails.orderInfo.receptionPlacePhone}
          />
          <OrderDetailTxt
            title="Typ zamówienia"
            value={orderDetails.orderInfo.type}
          />
          <OrderDetailTxt
            title="Pojazd"
            value={orderDetails.orderInfo.vehicle ? orderDetails.orderInfo.vehicle : "Brak"}
          />
          <OrderDetailTxt
            title="Dodatkowe Informacje"
            value={orderDetails.additionalInfo ? orderDetails.additionalInfo : "Brak"}
          />
          <OrderDetailTxt
            title="Forma Płatności"
            value={orderDetails.paymentType === 0 ? "Gotówka" : "Przelew"}
          />
          <OrderDetailTxt
            title="Ilość"
            value={orderDetails.orderInfo.quantity}
          />
          <OrderDetailTxt
            title="Wartość netto"
            value={(orderDetails.netPrice * orderDetails.quantity).toFixed(2).toString()}
          />
          <OrderDetailTxt
            title="Wartość brutto"
            value={(orderDetails.grossPrice * orderDetails.quantity).toFixed(2).toString()}
          />
          {orderDetails.kpo && <OrderDetailTxt
            title="KPO"
            value={orderDetails.kpo}
          />}
          {orderDetails.tonnage && <OrderDetailTxt
            title="Tonaż"
            value={orderDetails.tonnage}
          />}
          {orderDetails.internalStoreName && <OrderDetailTxt
            title="Magazyn wewnętrzny"
            value={orderDetails.internalStoreName}
          />}
          {orderDetails.recycleOperatorName && <OrderDetailTxt
            title="Operator"
            value={orderDetails.recycleOperatorName}
          />}
          {orderDetails.optimaStatusName && orderDetails.recycleOperatorName && <OrderDetailTxt
            title="Status synchronizacji - Optima"
            value={orderDetails.optimaStatusName}
          />}
          {orderDetails.optimaNumber && <OrderDetailTxt
            title="Numer Optima"
            value={orderDetails.optimaNumber}
          />}
          {orderDetails.invoiceNumber && <OrderDetailTxt
            title="Numer faktury"
            value={orderDetails.invoiceNumber}
          />}
          {cancelReason ? <OrderDetailTxt
            title="Powód anulowania"
            value={cancelReason}
          /> : null}
        </div>
      )}
    </div>
  );
};

export default OrderDetails;