import React from "react";
import { mergeStyles } from "office-ui-fabric-react";

import { Text, ITextStyles } from "office-ui-fabric-react";
import { FontSizes } from "@uifabric/fluent-theme";
import { FontWeights } from "office-ui-fabric-react";

import {
  titleS,
  subTitleS,
  textContainerS,
} from "../../styles/detailTxt.style";
import { useTranslation } from "react-i18next";


const wrapper = mergeStyles({
  display: "flex",
  justifyContent: "space-between",
});

const mainWrapper = mergeStyles({
  paddingTop: 20,
  paddingBottom: 15
})
const divider = mergeStyles({
  width: 250,
});

export interface PricesProps {
  value: number;
  quantity: number
}
const headerS: ITextStyles = {
  root: {
    display: "block",
    marginBottom: 15,
    fontSize: FontSizes.size16,
    fontWeight: FontWeights.semibold,
  },
};

const PricesShow: React.FC<PricesProps> = ({ value, quantity }) => {
  const { t } = useTranslation("orders");
  const priceShowNames: any = t("priceShow", {
    returnObjects: true,
  });


  return (
    <div className={mainWrapper} >
      <Text styles={headerS}>{priceShowNames.estimatedOrderCost}</Text>
      <div className={wrapper}>
        <div className={divider}>
          <div className={textContainerS}>
            <Text styles={titleS}>{priceShowNames.newValue}</Text>
            <Text styles={subTitleS}>
                {(value * quantity).toFixed(2).toString()} 
            </Text>
          </div>
        </div>
        <div className={divider}>
          <div className={textContainerS}>
            <Text styles={titleS}>{priceShowNames.grossValue}</Text>
            <Text styles={subTitleS}>
                {(value * quantity * 1.08).toFixed(2).toString()} 
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PricesShow;
