import { Form, Formik } from "formik";
import { mergeStyles, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import ErrorMessage from "../../../features/common/layout/form/ErrorMessage";
import FormDropDownReception from "../../../features/common/layout/form/FormDropDownReception";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import CommentInput from "../../../features/orders/components/forms/CommentInput";
import OrderParams from "../../../features/orders/components/forms/newOrder/OrderParams";
import OrderDetail from "../../../features/orders/components/forms/newOrder/OrderType";
import PricesShow from "../../../features/orders/components/forms/PricesShow";
import { useNewOrderFrom } from "../../../features/orders/hooks/useNewOrderForm";
import { divider } from "../../../features/orders/styles/newOrderForm.styles";
import { useOrderForm } from "../../hooks/orders/useOrderForm";
import { IOrdersOrderPanelLang } from "../../../localization/abstract/client/orders";

const margin = mergeStyles({
  marginTop: 15,
});

const marginB = mergeStyles({
  marginBottom: 15
})

export interface OrderNewClientFormProps { }

const OrderNewClientForm: React.FC<OrderNewClientFormProps> = () => {
  var companyId = sessionStorage.getItem("companyId");
  var departmentId = sessionStorage.getItem("departmentId");

  const [price, setPrice] = useState<any>(null);
  const [reception, setReception] = useState<number>(0);
  const [orderTypeItemId, setOrderTypeItemId] = useState<any>();

  const {
    handleSubmit,
    cancelForm,
    initValues,
    isLoading,
    receptionPlaces,
    orderType,
    orderTypeItems,
    orderDetails
  } = useOrderForm(companyId ? companyId : "", departmentId ? departmentId : "", reception ? reception : {});

  const {
    changePrice
  } = useNewOrderFrom();

  const { t } = useTranslation("orders");
  const ordersPanelNames: IOrdersOrderPanelLang = t("orderPanel", {
    returnObjects: true,
  });
  return (
    <Formik
      initialValues={initValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
    >
      {({ handleSubmit, setFieldValue, values }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack verticalAlign="space-between" verticalFill>
            <div className={margin}>
              <FormDropDownReception
                name="receptionPlace"
                headerText={ordersPanelNames.receptionPlace}
                options={receptionPlaces}
                orderTypeItemId={orderTypeItemId}
                setPrice={setPrice}
                setReception={setReception}
                onChange={changePrice}
              />
            </div>
            <div className={marginB}>
              <CommentInput name="additionalInfo" label={ordersPanelNames.additionalInformation} />
            </div>
            <div className={divider}>
              <OrderDetail title={ordersPanelNames.orderType} value={orderType.name} />
            </div>
            <OrderParams
              headerText={ordersPanelNames.details}
              setFieldValue={setFieldValue}
              values={values}
              orderType={orderDetails ? orderDetails.orderInfo ? orderDetails.orderInfo.type : "" : ""}
              items={orderTypeItems}
              setPrice={setPrice}
              reception={reception}
              setOrderTypeItemId={setOrderTypeItemId}
              onChange={changePrice}
            />
            <PricesShow
              value={price ? price : 0}
              quantity={initValues.quantity ? Number(values.quantity) : 1}
            />
            <div className={margin}>
              <NewFormBtns
                submitBtnText={ordersPanelNames.add}
                cancelForm={cancelForm}
                cancelBtnText={ordersPanelNames.cancel}
                disabled={isLoading}
                disabledOnlySubmitButton={!price} />
            </div>
            <div className={margin}>
              {isLoading ? <ActivityIndicator /> : <ErrorMessage />}
            </div>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default OrderNewClientForm;