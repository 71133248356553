import { IConfirmAcc, ISetNewPass } from "./../models/index";
import api from "../../../config/axios/configAxios";
import {
  ICompany,
  IDepartmentWithUser,
  IUserInvite,
  IUserLogin,
} from "../models/index";

export const verifyCompany = async (taxNumber: string) =>
  api.post(`/api/Companies/VerifyCompany`, { taxNumber });

export const registerCompany = async (data: ICompany) =>
  api.post("/api/Companies/RegisterCompany", data);

export const registerCompanyAdmin = async (data: ICompany) =>
  api.post("/api/Admin/Companies", data);

export const addSpecialPricesCompanyAdmin = async (id: string, data: any) => 
  api.put(`api/Admin/Companies/${id}/special-prices`,
  data
);

export const registerPersonAdmin = async  (data: ICompany) =>
  api.post('/api/Admin/Companies/person', data);

export const registerDepartment = async (
  data: IDepartmentWithUser,
  taxNumber: string
) => api.post(`/api/Companies/RegisterDepartment/${taxNumber}`, data);

export const registerUser = async (data: IUserInvite, departmentId: string) =>
  api.post(`/api/Companies/RegisterAccount/${departmentId}`, data);

export const inviteUser = async (data: IUserInvite) =>
  api.post(`/api/InvitedUsers`, data);

export const loginUser = async (data: IUserLogin) =>
  api.post(`/api/Authorization/SignIn`, data);

export const confirmUserAcc = async (data: IConfirmAcc) =>
  api.put(`/api/Authorization/ConfirmAccount`, data);

export const resetPassword = async (email: string) =>
  api.post(`/api/Authorization/RestorePassword/restore-password`, {
    userEmail: email,
  });

export const userInfo = async () =>
  api.get(`/api/Authorization/userinfo`);

export const setNewPass = async (data: ISetNewPass) => api.post(`/api/Authorization/SetNewPassword/setNewPassword`, data)
