import React from "react";

import { container } from "../../../styles/prices.styles";
import OrderDetailTxt from "../edditOrder/OrderDetailTxt";
import PricesShow from "../PricesShow";

export interface PricesProps {
    price: number,
    quantity: string,
    orderDetails: any
}

const Prices: React.FC<PricesProps> = ({price, quantity, orderDetails}) => {
  return (
    <div className={container}>
      <OrderDetailTxt title="Forma Płatności" value = {orderDetails.paymentType === 1 ? "Przelew" : "Gotówka"} />      
      <PricesShow 
        value = {price ? price : 0}
        quantity = {quantity ? Number(quantity) : 1}
                
      />
    </div>
  );
};

export default Prices;
