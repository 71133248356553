import React from "react";
import { Formik, Form } from "formik";

import { useRegisterDepartment } from "../../../../hooks/useRegisterDepartment";
import UserFields from "../user/UserFields";
import DepartmentFields from "./DepartmentsFields";
import FormBtns from "../../../../../common/layout/form/NewFormBtns";
import { validationSchemaDepartment } from "../../../../actions/validationSchema";
import InputHeader from "../../InputHeader";
import MessageContainer from "../MessageContainer";
import ActivityIndicator from "../../../../../common/layout/ActivityIndicator";
import { useUserData } from "../../../../../common/hooks/useUserData";

export interface RegisterDepartmentProps {}


const RegisterDepartment: React.FC<RegisterDepartmentProps> = () => {
  const { cancelForm, handleSubmit, isLoading } = useRegisterDepartment();
  const { user } = useUserData();
  const requireEmail = true;
  
  return (
    <Formik
      initialValues={{
        email: requireEmail ? '' : user && user.emails[0], 
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchemaDepartment}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <InputHeader text={"Dane użytkownika"} />
          <UserFields/>
          <InputHeader text={"Dane oddziału"} />
          <DepartmentFields />
          <FormBtns positionAbsolute cancelForm={cancelForm} submitBtnText="Zarejestruj"/>
          {isLoading ? <ActivityIndicator/> : <MessageContainer/>}
        </Form>
      )}
    </Formik>
  );
};

export default RegisterDepartment;
