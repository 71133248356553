import React, { useState } from "react";
import { Form, Formik } from "formik";
import NewFormBtns from "../../../common/layout/form/NewFormBtns";
import ActivityIndicator from "../../../common/layout/ActivityIndicator";
import FormDropDown from "../../../common/layout/form/FormDropDown";
import ClientsDropDown from "./newOrder/ClientsDropDown";
import CommentInput from "./CommentInput";
import OrderDetail from "./edditOrder/OrderDetailTxt";
import OrderParams from "./newOrder/OrderParams";
import { useNewOrderFrom } from "../../hooks/useNewOrderForm";
import { divider } from "../../styles/newOrderForm.styles";
import { newOrderValidation, newOrderValidationShort } from "../../actions/validationSchemas";
import FormDropDownReception from "../../../common/layout/form/FormDropDownReception";
import PricesShow from "./PricesShow";
import { Stack } from "office-ui-fabric-react";
import ReceptionPlacesDropDown from "./newOrder/ReceptionPlacesDropDown";

export interface OrderFormProps {
    departments: any;
    receptionPlaces: any;
    orderType: any;
    orderTypeItems: any;
    company: any;
    department: any;
    setCompany: any;
    setDepartment: any;
    cancelForm: any;
    handleSubmit: any;
    isLoading: any;
    setReception: any;
    reception: any;
    setOrderTypeItemId: any;
    orderTypeItemId: number
}

const NewOrderForm: React.FC<OrderFormProps> = ({ departments, receptionPlaces, orderType, orderTypeItems, company, department, setCompany,
    setDepartment, cancelForm, handleSubmit, isLoading,
    setReception, reception, setOrderTypeItemId, orderTypeItemId
}) => {
    const [price, setPrice] = useState<any>(null);
    const {
        changePrice
    } = useNewOrderFrom();
    if (company && company.data && (company.data.multiDepartment === false || departments.length === 1)) {
        if (departments[0]) {
            setDepartment(departments[0])
        }
    }
    // if(reception && company.data && company.data.multiDepartment === true){
    //     console.log(reception)
    //     setDepartment({key: reception.data.departmentId})
    // }
    // if (receptionPlaces.length == 1 && receptionPlaces[0] && !!receptionPlaces[0].key) {
    //     setReception(receptionPlaces[0].key);
    // }


    return (
        <Formik
            initialValues={{
                quantity: "1",
                reception: { reception },
                orderTypeItemId: { orderTypeItemId }
            }}
            onSubmit={handleSubmit}
            validateOnChange={false}
            validateOnBlur={false}
            validationSchema={newOrderValidationShort}
        >
            {({ handleSubmit, setFieldValue, resetForm, values }) => (
                <Form id='new-order-form' translate="yes" onSubmit={handleSubmit}>
                    <Stack>
                        <ClientsDropDown
                            name="company"
                            label="Klient"
                            setFieldValue={setFieldValue}
                            resetForm={resetForm}
                            value={company ? company.key : ""}
                            onChange={setCompany}
                            setDepartments={setDepartment}
                            company={company}
                        />
                        {(company && company.data && company.data.multiDepartment === true) ?
                            <div className={divider}>
                                <FormDropDown
                                    name="department"
                                    headerText="Oddział"
                                    options={departments}
                                    onChange={setDepartment}
                                    disabled={departments.length == 1 && departments[0] && departments[0].key}
                                    defaultSelectedKey={department ? department.key : departments.length == 1 && departments[0] && !!departments[0].key ? departments[0].key : null}
                                />
                            </div>
                            :
                            <></>
                        }
                        <div className={divider}>
                            {company.key == "" ?
                                <ReceptionPlacesDropDown
                                    name="receptionPlace"
                                    label="Punkt Odbioru"
                                    setFieldValue={setFieldValue}
                                    resetForm={resetForm}
                                    companyKey={company.key}
                                    value={""}
                                    departmentId={department}
                                    setCompany={setCompany}
                                    setReception={setReception}
                                    setDepartment={setDepartment}
                                />
                                :
                                <FormDropDownReception
                                    name="receptionPlace"
                                    headerText="Punkt Odbioru"
                                    options={receptionPlaces}
                                    orderTypeItemId={orderTypeItemId}
                                    setPrice={setPrice}
                                    setReception={setReception}
                                    onChange={changePrice}
                                    defaultSelectedKey={reception ? reception.key : receptionPlaces.length == 1 && receptionPlaces[0] && !!receptionPlaces[0].key ? receptionPlaces[0].key : null}
                                />

                            }
                        </div>
                        <CommentInput name="additionalInfo" label="Dodatkowe informacje" />
                        {company.key ?
                            <>
                                <div className={divider}>
                                    <OrderDetail title="Typ zamówienia" value={orderType.name} />
                                </div>
                                <OrderParams
                                    headerText="Szczegóły"
                                    setFieldValue={setFieldValue}
                                    values={values}
                                    orderType={orderType}
                                    items={orderTypeItems}
                                    setPrice={setPrice}
                                    reception={reception}
                                    setOrderTypeItemId={setOrderTypeItemId}
                                    onChange={changePrice}
                                />
                                <PricesShow
                                    value={price ? price : 0}
                                    quantity={values.quantity ? Number(values.quantity) : 1}

                                />
                            </>
                            :
                            <></>
                        }
                        <NewFormBtns
                            positionAbsolute={true}
                            submitBtnText="Zapisz"
                            cancelForm={cancelForm}
                            disabled={isLoading}
                            disabledOnlySubmitButton={!price}
                        />
                        <div>{isLoading && <ActivityIndicator />}</div>
                    </Stack>
                </Form>
            )}
        </Formik>
    );
};

export default NewOrderForm;