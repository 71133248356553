import { Form, Formik } from "formik";
import { mergeStyles, Stack } from "office-ui-fabric-react";
import * as React from "react";
import { useTranslation } from "react-i18next";
import FormHeader from "../../../features/clients/components/edditionPanel/FormHeader";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import ErrorMessage from "../../../features/common/layout/form/ErrorMessage";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import NewFormCheckbox from "../../../features/common/layout/form/NewFormCheckbox";
import NewFormField from "../../../features/common/layout/form/NewFormField";
import { receptionPlacesValidationSchema } from "../../actions/receptionPlacesValidationSchema";
import { useReceptionPlaceForm } from "../../hooks/receptionPlaces/useReceptionPlaceForm";
import { IReceptionPlacesPanelLang } from "../../../localization/abstract/client/receptionPlaces";

const margin = mergeStyles({
  marginTop: 15,
})

const padding = mergeStyles({
  paddingTop: 15,
})

export interface ReceptionPlaceFormProps {
  predefinedInitValues?: any;
}

const ReceptionPlaceForm: React.FC<ReceptionPlaceFormProps> = ({ predefinedInitValues }) => {
  const {
    handleSubmit,
    cancelForm,
    selectedItem,
    loading,
    initValues,
  } = useReceptionPlaceForm();
  const { t } = useTranslation("receptionPlaces");
  const repceptionPlacePanelNames: IReceptionPlacesPanelLang = t("panel", {
    returnObjects: true,
  });
  const validationSchemaNames: any = t("validationSchema", {
    returnObjects: true,
  });
  return (
    <Formik
      initialValues={!!initValues && Object.keys(initValues).length > 0 ? initValues : (!!predefinedInitValues && Object.keys(predefinedInitValues).length > 0 ? predefinedInitValues : initValues)}
      enableReinitialize={true}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={receptionPlacesValidationSchema(validationSchemaNames)}
    >
      {({ handleSubmit }) => (
        <Form translate="yes" onSubmit={handleSubmit}>
          <Stack verticalAlign="space-between" verticalFill>
            <FormHeader
              text={
                selectedItem ? repceptionPlacePanelNames.editReceptionPlace : repceptionPlacePanelNames.addReceptionPlace
              }
            />
            {selectedItem && sessionStorage.getItem("role") !== "ADMIN" ? null :
              <>
                <NewFormField name="city" label={repceptionPlacePanelNames.city} />
                <NewFormField name="street" label={repceptionPlacePanelNames.streetAndNumber}/>
                <NewFormField name="postalCode" label={repceptionPlacePanelNames.postalCode} />
                <NewFormField name="bdoNumber" label={repceptionPlacePanelNames.bdoNumber} />
              </>}
            <NewFormField name="phone" label={repceptionPlacePanelNames.phoneNumber}/>
            <div className={padding}>
              <NewFormCheckbox name="default" label={repceptionPlacePanelNames.defaultReceptionPlace} />
            </div>
            <div className={margin}>
              <NewFormBtns
                submitBtnText={selectedItem ? repceptionPlacePanelNames.save : repceptionPlacePanelNames.add }
                cancelBtnText={repceptionPlacePanelNames.cancel}
                cancelForm={cancelForm}
                positionAbsolute={true}
              />
            </div>
            {loading ? <ActivityIndicator /> : <ErrorMessage />}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ReceptionPlaceForm;