import { clearAlerts } from "./../../../config/redux/alerts/alertsActions";
import { refetchClientPanelData, toggleClientsPanel } from "./../../../config/redux/clients/panel/clientPanelActions";
import { addSpecialPriceToCompany, editCompanyInfo, getSpecialPrices } from "./../../../connectors/clients/connectors/index";
import { FormikValues } from "formik";
import { useMemo, useState, useEffect } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import {
  setErrorAlert,
  setSuccessAlert,
} from "../../../config/redux/alerts/alertsActions";
import {
  toggleEditing,
} from "../../../config/redux/clients/panel/clientPanelActions";
import { addNewClient } from "./../../../config/redux/clients/clientsActions";
import { Messages } from "../models/message.enum";
import { IResetForm } from "../../common/models/resetForm.type";
import { getOrderTypes } from "../../../connectors/orderTypes/connectors";
import { useQuery } from "react-query";

export const useBasicInfoForm = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const { basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  const {
    basicInformations: {
      shortName,
      bdoNumber,
      multiDepartment,
      id,
      companyName,
      taxNumber,
      orderTypeId,
      paymentType,
    },
    address,
  } = basicInfo;

  const {data} = useQuery("orderTypes", getOrderTypes);
  const prepareOrderTypes = () =>{
    var orderTypesMap =  data ? data.data.data : []
    var orderTypesa = Array<any>();
        orderTypesMap.map((x : any) => {
      var order = {
        key: x.id, 
        text: x.name
      }
      orderTypesa.push(order)
    })
    return orderTypesa;
};

useEffect(() => {
  const fetchSpecialPrices = async () => {
    try {
      const params = { CompanyId: id };
      await getSpecialPrices(params);
    } catch (error) {
      console.log(error);
    }
  };

  if (id) fetchSpecialPrices();
}, [id]);

const orderTypes = prepareOrderTypes()
  const initValues = useMemo(
    () => ({
      shortName: shortName ? shortName : "",
      bdoNumber: bdoNumber,
      multiDepartment: multiDepartment,
      city: address ? address.city : "",
      street: address ? address.street : "",
      postalCode: address ? address.postalCode : "",
      orderTypeId: orderTypeId ? orderTypeId : "",
      paymentType: paymentType ? (paymentType +1) : 1,
      id: id ? id : null,
    }),
    [basicInfo]
  );

  const readOnlyData = useMemo(
    () => ({
      name: companyName,
      taxNumber,
    }), 
    [basicInfo]
  );

const handleSubmit = async (
    values: FormikValues,
    specialPrices: any,
    { resetForm }: IResetForm
  ) => {
    setIsLoading(true);
    try {
      const body = {
        shortName: values.shortName,
        multidepartment: values.multiDepartment,
        bdoNumber: values.bdoNumber,
        orderTypeId: values.orderTypeId,
        paymentType: values.paymentType - 1,
        address: 
          {
            addressId: address.id,
            city: values.city,
            street: values.street,
            postalCode: values.postalCode,
          },  
        id: values.id,    
      };
      await editCompanyInfo(id, body);
      await addSpecialPriceToCompany(
        id,
        specialPrices
      );
      resetForm();
      setIsLoading(false);
      dispatch(clearAlerts());
      dispatch(toggleEditing({ editing: false }));
      dispatch(refetchClientPanelData(true));
      dispatch(addNewClient(true))
      dispatch(toggleClientsPanel(false))
      dispatch(setSuccessAlert(Messages.OPERATION_SUCCESS));
    } catch (err) {
      setIsLoading(false);
      dispatch(setErrorAlert("Firma posiada więcej niż jeden oddział"));
    }
  };

  return {
    initValues,
    handleSubmit,
    isLoading,
    readOnlyData,
    orderTypes 
  };
};