import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { NavigationPath } from "./NavigationPath";
import Auth from "../../pages/Auth";
import Home from "../../pages/Home";
//orders
import AllOrders from "../../features/orders/pages/AllOrders";
import NewOrders from "../../features/orders/pages/NewOrders";
import AcceptedOrders from "../../features/orders/pages/AcceptedOrders";
import InProgressOrders from "../../features/orders/pages/InProgressOrders";
import CompletedOrders from "../../features/orders/pages/CompletedOrders";
import InvoicedOrders from "../../features/orders/pages/InvoicedOrders";
import CanceledOrders from "../../features/orders/pages/CanceledOrders";
//clients
import UnverifiedClients from "../../features/clients/pages/UnverifiedClients";
import ActiveClients from "../../features/clients/pages/ActiveClients";
import InactiveClients from "../../features/clients/pages/InactiveClients";
import UnactiveUsers from "../../features/unactiveusers/pages/UnactiveUsers";
//config
import OrderTypes from "../../features/orderTypes/pages/OrderTypes";
import Vehicles from "../../features/vehicles/pages/Vehicles";
import Pricing from "../../features/Pricing/components/Pricing";
//layouts  routes
import { AdminLayoutRoute } from "./AdminLayoutRoute";
import { AdminClientLayoutRoute } from "./AdminClientLayoutRoute";
import Layout from "../../features/common/layout/Layout";
//commandBars
import OrdersComandBar from "../../features/commandBar/components/orders/OrdersCommandBar";
import ClientsCommandBar from "../../features/commandBar/components/clients/ClientsCommandBar";
import DriversCommandBar from "../../features/commandBar/components/settings/DriversCommandBar";
import OrderTypeCommandBar from "../../features/commandBar/components/settings/OrderTypeCommandBar";
import UnactiveUsersCommandBar from "../../features/commandBar/components/UnactiveUsersCommandBar";
import VehiclesCommandBar from "../../features/commandBar/components/settings/VehiclesCommandBar";
//nav
import ClientsNav from "../../features/nav/components/ClientsNav";
import ValidatorsNav from "../../features/nav/components/ValidatorsNav";
import OrdersNav from "../../features/nav/components/OrdersNav";
import Drivers from "../../features/drivers/pages/Drivers";
import PricingCommandBard from "../../features/commandBar/components/pricing/PricingCommandBar";
import Operators from "../../features/operators/components/Operators";
import OperatorsCommandBard from "../../features/commandBar/components/operators/OperatorsCommandBar";
import CancellationResons from "../../features/cancellationReasons/components/CancellationReasons";
import Admins from "../../features/admins_last/pages/Admins";
import CancellationReasonsCommandBar from "../../features/commandBar/components/settings/CancellationReasonsCommandBar";
import AdminsCommandBar from "../../features/commandBar/components/settings/AdminsCommandBar";
import NewPass from "../../pages/NewPass";
import { LayoutRoute } from "./LayoutRoute";
import Dashboard from "../../client/Pages/Dashboard";
import ClientReceptionPlaces from "../../client/Pages/ReceptionPlaces";
import ClientReceptionPlacesCommandBar from "../../client/components/commandBar/ClientReceptionPlacesCommandBar";
import Orders from "../../client/Pages/Orders";
import ClientOrdersCommandBar from "../../client/components/commandBar/ClientOrdersCommandBar";
import Inactive from "../../pages/Inactive";
import Unavailable from "../../pages/Unavailable";
import ActivityIndicator from "../../features/common/layout/ActivityIndicator";
import Users from "../../features/users/pages/Users";
import UsersCommandBar from "../../features/commandBar/components/settings/UsersCommandBar";
import Reports from "../../features/reports/components/Reports";
import UnactivePickupPointsCommandBar from "../../features/commandBar/components/UnactivePickupPointsCommandBar";
import UnactivePickupPoints from "../../features/unactivepickkupoints/pages/UnactivePickupPoints";
import { useUserInfo } from "../../features/common/hooks/useUserInfo";
import ReportsCommandBar from "../../features/reports/commandBar/ReportsCommandBar";
import Store from "../../features/store/components/Store";
import StoreCommandBar from "../../features/store/commandBar/StoreCommandBar";
import StoreDocs from "../../features/storeDocs/components/StoreDocs";
import StoreDocsCommandBar from "../../features/storeDocs/commandBar/StoreDocsCommandBar";
import StoreMapCommandBar from "../../features/storeMap/commandBar/StoreMapCommandBar";
import { StoreMap } from "../../features/storeMap/components/StoreMap";
import InternalStore from "../../features/internalStore/components/InternalStore";
import InternalStoreCommandBar from "../../features/internalStore/commandBar/internalStoreCommandBar";
import ReceptionPlaces from "../../features/receptionPlaces/pages/ReceptionPlaces";
import ReceptionPlacesCommandBar from "../../features/receptionPlaces/commandBar/ReceptionPlaceCommandBar";
import ContainersCommandBar from "../../features/containers/commandBar/ContainersCommandBar";
import Containers from "../../features/containers/pages/Containers";
import { useTranslation } from "react-i18next";
import ValidatorsCommandBar from "../../features/commandBar/components/validators/ValidatorsCommandBar";
import ContainersValidators from "../../features/validators/pages/ContainersValidators";
import ReceptionPlacesValidators from "../../features/validators/pages/RecetionPlacesValidators";
import OrdersValidators from "../../features/validators/pages/OrdersValidators";
import ValidatorTypes from "../../features/validatorTypes/components/ValidatorTypes";
import PriceListNav from "../../features/nav/components/PriceListNav";
import DetailedPriceCommandBar from "../../features/commandBar/components/detailedPrice/DetailedPriceCommandBar";
import DetailedPrice from "../../features/detailedPriceList/pages/DetailedPrice"
import ContainterModalMapFromURL from "../../features/containers/pages/ContainterModalMapFromURL";
import NotConfirmedUsers from "../../features/notConfirmedUsers/components/NotConfirmedUsers";
import NotConfirmedUsersCommandBar from "../../features/commandBar/components/settings/NotConfirmedUsersCommandBar";
import DriverAppMessage from "../../pages/DriverAppMessage";

const Routing: React.FC = () => {

    const { data, status, error } = useUserInfo();
    const fetchedData = data ? data.data : undefined
    const isActivated = fetchedData ? fetchedData.isActivated : 'false';
    const hasAllRequiredData = fetchedData ? fetchedData.hasAllRequiredData : 'false';
    const role = fetchedData ? fetchedData.role : '';
    const companyId = fetchedData ? fetchedData.companyId : '';
    const departmentId = fetchedData ? fetchedData.departmentId : '';
    sessionStorage.setItem('isActivated', isActivated);
    sessionStorage.setItem('hasAllRequiredData', hasAllRequiredData);
    sessionStorage.setItem('role', role);
    sessionStorage.setItem('companyId', companyId);
    sessionStorage.setItem('departmentId', departmentId);

    const { t } = useTranslation("common");
    const loadingTranslation = t("loading");

    if (status === "loading" && !error) {
        return <>
            <ActivityIndicator text={loadingTranslation} />
        </>
    }
    if (error) return <Unavailable queryStatus={status} />;
    return (
        <Switch>
            <AdminClientLayoutRoute
                exact
                path={NavigationPath.CLIENT_USERS}
                component={Users}
                commandBar={UsersCommandBar}
            />
            <AdminLayoutRoute
                exact
                path={NavigationPath.HOME}
                layout={Layout}
                component={Home}
            />
    //client
            <LayoutRoute
                exact
                path={NavigationPath.CLIENT_DASHBOARD}
                component={Dashboard}
            />
            <LayoutRoute
                exact
                path={NavigationPath.CLIENT_RECEPTION_PLACES}
                component={ClientReceptionPlaces}
                commandBar={ClientReceptionPlacesCommandBar}
            />
            <LayoutRoute
                exact
                path={NavigationPath.CLIENT_ORDERS}
                commandBar={ClientOrdersCommandBar}
                component={Orders}
            />
      //auth
            <Route path={NavigationPath.AUTH} component={Auth} />
            <Route path={NavigationPath.NEW_PASS} component={NewPass} />
            <Route path={NavigationPath.INACTIVE_ACCOUNT} component={Inactive} />
            <Route path={NavigationPath.UNAVAILABLE} component={Unavailable} />
            <Route path={NavigationPath.DRIVER_APP_MESSAGE} component={DriverAppMessage} />
      //orders
            <AdminLayoutRoute
                component={AllOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.ALL_ORDERS}
            />
            <AdminLayoutRoute
                component={NewOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.NEW_ORDERS}
            />
            <AdminLayoutRoute
                component={AcceptedOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.ACCEPTED_ORDERS}
            />
            <AdminLayoutRoute
                component={InProgressOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.IN_PROGRESS_ORDERS}
            />
            <AdminLayoutRoute
                component={CompletedOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.COMPLETED_ORDERS}
            />
            <AdminLayoutRoute
                component={InvoicedOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.INVOICED_ORDERS}
            />
            <AdminLayoutRoute
                component={CanceledOrders}
                layout={Layout}
                commandBar={OrdersComandBar}
                sideNav={OrdersNav}
                path={NavigationPath.CANCELED_ORDERS}
            />
        //validators
            <AdminLayoutRoute
                component={ContainersValidators}
                layout={Layout}
                commandBar={ValidatorsCommandBar}
                sideNav={ValidatorsNav}
                path={NavigationPath.CONTAINERS_VALIDATORS}
            />
            <AdminLayoutRoute
                component={ReceptionPlacesValidators}
                layout={Layout}
                commandBar={ValidatorsCommandBar}
                sideNav={ValidatorsNav}
                path={NavigationPath.RECEPTION_PLACES_VALIDATORS}
            />
            <AdminLayoutRoute
                component={OrdersValidators}
                layout={Layout}
                commandBar={ValidatorsCommandBar}
                sideNav={ValidatorsNav}
                path={NavigationPath.ORDERS_VALIDATORS}
            />
        //price list
            <AdminLayoutRoute
                component={Pricing}
                layout={Layout}
                commandBar={PricingCommandBard}
                sideNav={PriceListNav}
                path={NavigationPath.GENERAL_PRICE_LIST}
            />
            <AdminLayoutRoute
                component={DetailedPrice}
                layout={Layout}
                commandBar={DetailedPriceCommandBar}
                sideNav={PriceListNav}
                path={NavigationPath.DETAILED_PRICE_LIST}
            />
      //clients
            <AdminLayoutRoute
                component={UnverifiedClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.UNVERIFIED_CLIENTS}
            />
            {/* <AdminLayoutRoute
        component={NewClients}
        layout={Layout}
        commandBar={ClientsCommandBar}
        sideNav={ClientsNav}
        path={NavigationPath.NEW_CLIENTS}
      /> */}
            <AdminLayoutRoute
                component={ActiveClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.ACTIVE_CLIENTS}
            />
            <AdminLayoutRoute
                component={InactiveClients}
                layout={Layout}
                commandBar={ClientsCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.INACTIVE_CLIENTS}
            />
            <AdminLayoutRoute
                component={ReceptionPlaces}
                layout={Layout}
                commandBar={ReceptionPlacesCommandBar}
                sideNav={ClientsNav}
                path={NavigationPath.RECEPTION_PLACES}
            />
            <AdminLayoutRoute
                component={UnactiveUsers}
                layout={Layout}
                commandBar={UnactiveUsersCommandBar}
                path={NavigationPath.UNACTIVE_USERS}
            />
            <AdminLayoutRoute
                component={UnactivePickupPoints}
                layout={Layout}
                commandBar={UnactivePickupPointsCommandBar}
                path={NavigationPath.UNACTIVE_PICKUP_POINTS}
            />

      //config
            <AdminLayoutRoute
                component={OrderTypes}
                layout={Layout}
                commandBar={OrderTypeCommandBar}
                path={NavigationPath.ORDER_TYPES}
            />
            <AdminLayoutRoute
                component={Vehicles}
                layout={Layout}
                commandBar={VehiclesCommandBar}
                path={NavigationPath.VEHICLES}
            />
            <AdminLayoutRoute
                component={Drivers}
                layout={Layout}
                commandBar={DriversCommandBar}
                path={NavigationPath.DRIVERS}
            />
            <AdminLayoutRoute
                component={Operators}
                layout={Layout}
                commandBar={OperatorsCommandBard}
                path={NavigationPath.OPERATORS}
            />
            <AdminLayoutRoute
                component={CancellationResons}
                layout={Layout}
                commandBar={CancellationReasonsCommandBar}
                path={NavigationPath.CANCELLATION_REASONS}
            />
            <AdminLayoutRoute
                component={ValidatorTypes}
                layout={Layout}
                path={NavigationPath.VALIDATOR_TYPES}
            />
            <AdminLayoutRoute
                component={NotConfirmedUsers}
                layout={Layout}
                commandBar={NotConfirmedUsersCommandBar}
                path={NavigationPath.NOT_CONFIRMED_USERS}
            />
            <AdminLayoutRoute
                component={Admins}
                layout={Layout}
                commandBar={AdminsCommandBar}
                path={NavigationPath.ADMINS}
            />
            <AdminLayoutRoute
                component={Reports}
                layout={Layout}
                commandBar={ReportsCommandBar}
                path={NavigationPath.REPORT_LIST}
            />
            <AdminLayoutRoute
                component={Store}
                layout={Layout}
                commandBar={StoreCommandBar}
                path={NavigationPath.STORE_LIST}
            />
            <AdminLayoutRoute
                component={InternalStore}
                layout={Layout}
                commandBar={InternalStoreCommandBar}
                path={NavigationPath.INTERALSTORE_LIST}
            />
            <AdminLayoutRoute
                component={StoreDocs}
                layout={Layout}
                commandBar={StoreDocsCommandBar}
                path={NavigationPath.STORE_DOCS_LIST}
            />
            <AdminLayoutRoute
                component={Containers}
                layout={Layout}
                commandBar={ContainersCommandBar}
                path={NavigationPath.CONTAINERS_LIST}
            />
            <AdminLayoutRoute
                component={ContainterModalMapFromURL}
                layout={Layout}
                path={NavigationPath.STORE_CONTAINER_MAP}
            />
            <AdminLayoutRoute
                component={StoreMap}
                layout={Layout}
                commandBar={StoreMapCommandBar}
                path={NavigationPath.STORE_MAP}
            />
        </Switch>
    );
};

export default Routing;