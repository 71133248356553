import React from "react";
import {
  Stack,
  PrimaryButton,
  IButtonStyles,
  mergeStyles,
} from "office-ui-fabric-react";
import { Formik, Form } from "formik";
import { validationSchemaNipNotRequired } from "../../../actions/validationSchema";
import { useVerification } from "../../../hooks/useVerification";
import ActivityIndicator from "../../../../common/layout/ActivityIndicator";
import NewFormField from "../../../../common/layout/form/NewFormField";
import MessageContainer from "./MessageContainer";
import { DefaultButton } from "@fluentui/react/lib/components/Button/DefaultButton/DefaultButton";

const formS = {
  height: "100%",
};

const buttonS: Partial<IButtonStyles> = {
  root: {
    width: "200px",
    position: "absolute",
    bottom: 30,
    left: 40,
  },
};

const padding = mergeStyles({
  paddingTop: 15,
});

export interface IInitValues {
  nip: string;
}

const initValues = {
  nip: "",
};

const NipForm: React.FC = () => {
  const { handleVerification, loading } = useVerification();

  return (
    <>
      <Formik
        initialValues={initValues}
        onSubmit={handleVerification}
        validateOnBlur
        validationSchema={validationSchemaNipNotRequired}
      >
        {({ handleSubmit }) => (
          <Form translate="yes" onSubmit={handleSubmit} style={formS}>
            <Stack verticalFill verticalAlign="start" horizontalAlign="start">
              <NewFormField
                name="nip"
                label="NIP"
                placeholder="podaj nip bez kresek"
                required={false}
              />

              <PrimaryButton
                text="Dalej"
                styles={buttonS}
                type="submit"
              />

              <div className={padding}>
                {loading ? <ActivityIndicator /> : <MessageContainer />}
              </div>
            </Stack>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default NipForm;