import { FormikValues } from "formik";
import { useMemo, useState } from "react";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { reloadOrders } from "../../../config/redux/orders/ordersActions";
import { toggleOrderPanel } from "../../../config/redux/orders/ordersPanel/orderPanelActions";
import { setErrorAlert, setListMessage } from "../../../config/redux/alerts/alertsActions";
import { OrdersAlerts } from "../models/Alerts.enum";
import { toggleFiltration } from "../../../config/redux/filters/filterActions";
import { correctOrder } from "../../../connectors/orders/connectors";

export const useCorrectOrderForm = () => {

    const { orderDetails } = useSelector((state: RootStateOrAny) => state.orderPanel);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();

    const initValues = useMemo(
        () => ({
            tonnage: orderDetails ? orderDetails.tonnage : null,
            orderId: orderDetails ? orderDetails.orderInfo.id : null
        }),
        [orderDetails]
    );

    const handleSubmit = async (values: FormikValues, formikBag: any) => {
        setIsLoading(true)
        console.log(values);
        const { tonnage, orderId } = values;
        try {
            var res = await correctOrder(orderId,
                {
                    orderId,
                    tonnage: parseTonnage(tonnage)
                }
            );
                
            dispatch(toggleFiltration({ filter: true }));
            dispatch(reloadOrders(true));
            formikBag.resetForm();
            dispatch(toggleOrderPanel(false));
            dispatch(setListMessage("Pomyślnie poprawiono zamówienie"));
        } catch (err) {
            dispatch(setErrorAlert(OrdersAlerts.FILL_ALL_FILEDS));
            dispatch(toggleOrderPanel(false));
        }
        setIsLoading(false)
    };

    const parseTonnage = (tonnage: any) => {
        return tonnage.replace(',', '.');
      }
    const cancelForm = () => {
        dispatch(toggleOrderPanel(false));
    };

    return {
        orderDetails,
        initValues,
        handleSubmit,
        cancelForm,
        isLoading
    }
}