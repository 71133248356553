import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import ActivityIndicator from "../../../features/common/layout/ActivityIndicator";
import NewFormBtns from "../../../features/common/layout/form/NewFormBtns";
import CommentInput from "../../../features/orders/components/forms/CommentInput";
import OrderParams from "../../../features/orders/components/forms/newOrder/OrderParams";
import { useNewOrderFrom } from "../../../features/orders/hooks/useNewOrderForm";
import { useOrderForm } from "../../hooks/orders/useOrderForm";
import OrderBasicInfo from "../../../features/orders/components/forms/edditOrder/OrderBasicInfo";
import PaymentMethod from "../../../features/orders/components/forms/edditOrder/PaymentMethod";
import MessageContainer from "../../../features/auth/components/register/forms/MessageContainer";
import Prices from "../../../features/orders/components/forms/Prices";
import { containerS } from "../../../features/orders/styles/containerStyles";
import FormDropDownReception from "../../../features/common/layout/form/FormDropDownReception";
import { useTranslation } from "react-i18next";
import { IOrdersOrderPanelLang } from "../../../localization/abstract/client/orders";

export interface OrderEditClientFormProps {
  selectedItem: any;
}

const OrderEditClientForm: React.FC<OrderEditClientFormProps> = ({
}) => {
  var companyId = sessionStorage.getItem("companyId");
  var departmentId = sessionStorage.getItem("departmentId");

  const [reception, setReception] = useState<any>(undefined);
  const [orderTypeItemId, setOrderTypeItemId] = useState<number>(0);

  const {
    handleSubmit,
    cancelForm,
    initValues,
    isLoading,
    receptionPlaces,
    orderTypeItems,
    orderDetails

  } = useOrderForm(companyId ? companyId : "", departmentId ? departmentId : "", reception ? reception : "");

  const [price, setPrice] = useState<number>(orderDetails.netPrice);
  useEffect(() => {

    if (orderDetails && orderDetails.receptionPlaceId) {
      setReception({key: orderDetails.receptionPlaceId})
      localStorage.setItem("receptionPlace", orderDetails.receptionPlaceId)
    }

    if(orderDetails && orderDetails.orderTypeItemId){
      setOrderTypeItemId(orderDetails.orderTypeItemId)
      localStorage.setItem("orderTypeItemId", orderDetails.orderTypeItemId.toString())
    }

    if (orderDetails && orderDetails.netPrice) {
      setPrice(orderDetails.netPrice);
    }

  }, [orderDetails]);

  const {
    changePrice
  } = useNewOrderFrom();

  // if ((reception == undefined || reception === 0) && initValues.receptionPlace === undefined && orderDetails.receptionPlaceId !== undefined) {
  //   console.log("check")
  //   setReception(orderDetails.receptionPlaceId);
  // }

  const { t } = useTranslation("orders");
  const orderPanelNames: IOrdersOrderPanelLang = t("orderPanel", {
    returnObjects: true,
  });


  return (
    <div className={containerS}>
      <OrderBasicInfo activeOrder={orderDetails} />
      <Formik
        enableReinitialize={true}
        initialValues={initValues}
        onSubmit={handleSubmit}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, setFieldValue, handleChange }) => (
          <Form>
            <FormDropDownReception
              name="receptionPlace"
              headerText={orderPanelNames.receptionPlace}
              options={receptionPlaces}
              onChange={changePrice}
              setReception={setReception}
              orderTypeItemId={orderTypeItemId}
              setPrice={setPrice}
              changePrice={changePrice}
            />
            <CommentInput
              value={initValues.additionalInfo ? initValues.additionalInfo : ""}
              name="additionalInfo"
              onChange={handleChange}
              label={orderPanelNames.additionalInformation}
            />
            <OrderParams
              headerText={orderPanelNames.details}
              orderType={orderDetails ? orderDetails.orderInfo ? orderDetails.orderInfo.type : "" : ""}
              setFieldValue={setFieldValue}
              values={values}
              items={orderTypeItems}
              setPrice={setPrice}
              onChange={changePrice}
              setOrderTypeItemId={setOrderTypeItemId}
              reception={reception ? reception : 0}
            />
            <PaymentMethod
              setFieldValue={setFieldValue}
              paymentType={orderDetails.paymentType}
            />
            <Prices
              value={price !== null ? price : 0}
              setPrice={setPrice}
              disabled={price ? false : true}
            />
            <NewFormBtns
              positionAbsolute={true}
              submitBtnText={orderPanelNames.save}
              cancelBtnText={orderPanelNames.cancel}
              cancelForm={cancelForm}
              disabled={isLoading}
              disabledOnlySubmitButton={!price} />
            {isLoading ? <ActivityIndicator /> : <MessageContainer />}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default OrderEditClientForm;