import React, { useCallback } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { Pivot, PivotItem } from "office-ui-fabric-react/lib/Pivot";

import ClientBasicInfo from "./displayData/ClientBasicInfo";
import PickUpPointsForm from "./forms/PickUpPointsForm";
import UsersForm from "./forms/UsersForm";
import DepartmentsForm from "./forms/DepartmentsForm";
import CompanyDetailData from "./CompanyDetailData";
import {
  departmentsColumns,
  generateDepartments,
} from "../colums/departments-columns";
import {  generateUsers } from "../colums/users-columns";
import {
  pickUpPointsColumns,
  generatePickUpPoints,
  pickUpPointsColumnsShort,
} from "../colums/pickUpPoints-columns";
import {
  getDepartments,
  getUsers,
  getReceptionPlaces,
  getCompaniesAttachments,
} from "../../../../connectors/clients/connectors/index";
import {
  setActiveDepartment,
  setActivePickUpPoint,
  setActiveUser,
  clearData,
  setActiveTab,
  setActiveFiles,
} from "../../../../config/redux/clients/panel/clientPanelActions";
import { clearAlerts } from "../../../../config/redux/alerts/alertsActions";
import BasicInfoForm from "./forms/BasicInfoForm";
import { DirectionalHint, FontIcon, TooltipDelay, TooltipHost } from "office-ui-fabric-react";
import FilesUploadForm from "./forms/FilesUploadForm";
import { filesColumns, generateFilesList } from "../colums/files-columns";

export enum TabsNames {
  BASIC_INFO = "Informacje podstawowe",
  PICKUP_POINTS = "Punkty odbioru",
  USERS = "Użytkownicy",
  DEPARTMENTS = "Oddziały",
  FILES = "Pliki"
}

const ClientTabs: React.FC = () => {
  const dispatch = useDispatch();
  const { editing, reload, basicInfo } = useSelector(
    (state: RootStateOrAny) => state.clientPanel
  );

  const handleClick = useCallback((e: any) => {
    dispatch(setActiveTab(e.props.id));
    dispatch(clearAlerts());
    dispatch(clearData());
  }, []);

  const usersColumns = [
    {
      key: "column1",
      name: "Email",
      fieldName: "email",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => ( item.isInvitationInProgress ?
        <><TooltipHost
        content="Wysłano zaproszenie"
        delay={TooltipDelay.zero}
        directionalHint={DirectionalHint.bottomCenter}
      ><FontIcon iconName='mail'/> </TooltipHost>{item.email}</> : item.email
      ),
    },
    {
      key: "column2",
      name: "Imię",
      fieldName: "firstNamee",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column5",
      name: "Oddział",
      fieldName: "departmentName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  const usersColumnsShort = [
    {
      key: "column1",
      name: "Email",
      fieldName: "email",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
      onRender: (item: any) => ( item.isInvitationInProgress ?
        <><TooltipHost
        content="Wysłano zaproszenie"
        delay={TooltipDelay.zero}
        directionalHint={DirectionalHint.bottomCenter}
      ><FontIcon iconName='mail'/> </TooltipHost>{item.email}</> : item.email
      ),
    },
    {
      key: "column2",
      name: "Imię",
      fieldName: "firstName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column3",
      name: "Nazwisko",
      fieldName: "lastName",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
    {
      key: "column4",
      name: "Telefon",
      fieldName: "phone",
      minWidth: 50,
      maxWidth: 100,
      isResizable: true,
    },
  ];

  return (
    <Pivot onLinkClick={handleClick}>
      <PivotItem headerText={TabsNames.BASIC_INFO} id={TabsNames.BASIC_INFO}>
        {basicInfo && (editing ? <BasicInfoForm /> : <ClientBasicInfo />)}
      </PivotItem>
      {}
      <PivotItem
        headerText={TabsNames.PICKUP_POINTS}
        id={TabsNames.PICKUP_POINTS}
      >
        {editing ? (
          <PickUpPointsForm />
        ) : (
          <CompanyDetailData
            columns={
              basicInfo.hasOwnProperty("basicInformations") &&
              basicInfo.basicInformations.multiDepartment
                ? pickUpPointsColumns
                : pickUpPointsColumnsShort
            }
            generateItems={generatePickUpPoints}
            getData={getReceptionPlaces}
            setActiveData={setActivePickUpPoint}
            change={reload}
          />
        )}
      </PivotItem>
      <PivotItem headerText={TabsNames.USERS} id={TabsNames.USERS}>
        {editing ? (
          <UsersForm />
        ) : (
          <CompanyDetailData
            columns={basicInfo.basicInformations && basicInfo.basicInformations.multiDepartment ? usersColumns : usersColumnsShort}
            generateItems={generateUsers}
            getData={getUsers}
            setActiveData={setActiveUser}
            change={reload}
          />
        )}
      </PivotItem>
      {basicInfo.hasOwnProperty("basicInformations") &&
        basicInfo.basicInformations.multiDepartment && (
          <PivotItem
            headerText={TabsNames.DEPARTMENTS}
            id={TabsNames.DEPARTMENTS}
          >
            {editing ? (
              <DepartmentsForm />
            ) : (
              <CompanyDetailData
                columns={departmentsColumns}
                generateItems={generateDepartments}
                getData={getDepartments}
                setActiveData={setActiveDepartment}
                change={reload}
              />
            )}
          </PivotItem>
        )}
         <PivotItem 
          headerText={TabsNames.FILES} 
          id={TabsNames.FILES}>
                    {editing ? (
                        <FilesUploadForm />
        ) : (
          <CompanyDetailData
            columns={filesColumns
            }
            generateItems={generateFilesList}
            getData={getCompaniesAttachments}
            setActiveData={setActiveFiles}
            change={reload}
          />)}

         </PivotItem>
    </Pivot>
  );
};

export default ClientTabs;
