import React, { useEffect } from "react";
import { DatePicker, DayOfWeek, IMessageBarStyles, Label, MessageBar, MessageBarType } from "office-ui-fabric-react";
import { useField } from "formik";
import { datePickerStrings } from "../../../commandBar/components/orders/datePicker.data";

const errorMessageS: IMessageBarStyles = {
    root: {
      marginTop: "15px",
      width: "100%",
    },
  };

interface IFormDatePickerProps {
  name: string;
  values: any;
  label?: string;
  required?: boolean;
  placeholder: string; 
}

const FormDatePicker: React.FC<IFormDatePickerProps> = ({
  name,
  values,
  label,
  required,
  placeholder
}) => {
  const [firstDayOfWeek, setFirstDayOfWeek] = React.useState(DayOfWeek.Sunday);
  const [field, { value, error }, { setValue }] = useField(name);
  const [date, setDate] = React.useState<Date | null | undefined>(null);

  const onSelectDate = (date: Date | null | undefined): void => {
    setDate(date);

    const formatedDate = onFormatDate(date);
    setValue(formatedDate);
  };
  const today: Date = new Date(Date.now());
  const onFormatDate = (date: any): any => {
    const formatedDate = `${date.getFullYear()}-${add0toDate(
      date.getMonth() + 1
    )}-${add0toDate(date.getDate())}`;

    return formatedDate;
  };

  return (
    <div >
      {label ? <Label required={required}>
        {label}
      </Label> : null}
      <DatePicker
        firstDayOfWeek={firstDayOfWeek}
        strings={datePickerStrings}
        placeholder={placeholder}
        ariaLabel="Wybierz datę"
        value={date!}
        onSelectDate={onSelectDate}
        formatDate={onFormatDate}
      />
      {error && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          dismissButtonAriaLabel="Close"
          styles={errorMessageS}
        >
          {error}
        </MessageBar>
      )}
    </div>
  );
};

export default FormDatePicker;

const add0toDate = (date: any) => {
  if (parseInt(date) < 10) {
    return `0${date}`;
  }

  return date;
};
