import * as yup from "yup";

export const receptionPlacesValidationSchema = (validationSchemaNames: any) => yup.object().shape({
  city: yup
    .string()
    .required(validationSchemaNames.checkCity),
  street: yup
    .string()
    .required(validationSchemaNames.checkStreet),
  postalCode: yup
    .string()
    .required(validationSchemaNames.checkPostalCode)
    .matches(/^[0-9, -]+$/, validationSchemaNames.checkPostalCode)
    .min(6, validationSchemaNames.checkPostalCode)
    .max(6, validationSchemaNames.checkPostalCode),
  phone: yup
    .string()
    .required(validationSchemaNames.checkPhoneNumber)
    .matches(/^[0-9]+$/, validationSchemaNames.checkPhoneNumber)
    .min(9, validationSchemaNames.checkPhoneNumber)
    .max(9, validationSchemaNames.checkPhoneNumber),
  bdoNumber: yup
    .string()
    .required(validationSchemaNames.checkBDONumer)
    .matches(/^[0-9]+$/, validationSchemaNames.checkBDONumer)
    .nullable()
});